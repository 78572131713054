import React from 'react';
import logo from "./logo.svg";

export default class NavBar extends React.Component {
    render() {
        return (
            <nav className="flex justify-between ">
                <a className="link hover-white h-100 w-25  no-underline flex items-center" href="#hd">
                    <img src={logo}  className="w-100" alt="logo hd"/>
                </a>
                <div className="cb cn-ns flex-grow pa3 flex items-center">
                    <a className="link dim gray f6 f5-ns dib mr5-l mr4-m mr2" href="#tag-servicios" title="About">Servicios</a>
                    <a className="link dim gray f6 f5-ns dib mr5-l mr4-m mr2" href="#tag-hd" title="Store">HD</a>
                    <a className="link dim gray f6 f5-ns dib" href="#tag-footer" title="Contact">Contacto</a>
                </div>
            </nav>
        );
    }
}
