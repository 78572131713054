import React from 'react';
import { AtSign, Mail} from 'react-feather';

export default class Footer extends React.Component {
    render() {
        return (
            <footer id="tag-footer" className="fl pa2 pa4-ns mt3-ns pa5-l black-60 b--black-20">

                <div className="mb4-l cf">
                    <h4 className="fl w-100 pv0 f6 fw6 ttu tracked mb2 mb3-m mb4-l">Sucursales</h4>

                    <article className="fl w-100 w-50-m w-25-l dib-ns w-auto-ns mb4">
                        <h6 className="f6 f4-l f5-m fw6">ORINOQUIA</h6>
                        <span className="f7 f6-l black-70">fabian.gonzalez<AtSign size={12}/>hdsas.co</span>
                        <a className="f6 db fw6 black-70 link dim" title="Call Bogota" href="tel:+573053714637">
                            ( +57 ) 305 371 46 37
                        </a>
                    </article>

                    <article className="fl w-100 w-50-m w-25-l dib-ns w-auto-ns mb4">
                        <h6 className="f6 f4-l f5-m fw6">VALLE DEL CAUCA</h6>
                        <span className="f7 f6-l black-70">julio.gonzalez<AtSign size={12}/>hdsas.co</span>
                        <a className="f6 db fw6 black-70 link dim" title="Call Bogota" href="tel:+573209256153">
                            ( +57 ) 310 329 71 09
                        </a>
                    </article>

                    <article className="fl w-100 w-50-m w-25-l dib-ns w-auto-ns mb4">
                        <h6 className="f6 f4-l fw6">SANTANDERES</h6>
                        <span className="f7 f6-l black-70">comercialsantanderes<AtSign size={12}/>hdsas.co</span>
                        <a className="f6 db fw6 black-70 link dim" title="Call Bogota" href="tel:+573209256153">
                            ( +57 ) 305 371 46 37
                        </a>
                    </article>

                    <article className="fl w-100 w-50-m w-25-l dib-ns w-auto-ns mb4">
                        <h6 className="f6 f4-l f5-m fw6">BOGOTA D.C.</h6>
                        <span className="f7 f6-l db black-70">Calle 64 # 70 – 68</span>
                        <span className="f7 f6-l black-70">contactenos<AtSign size={12}/>hdsas.co</span>
                        <a className="f6 db fw6 pt1 pt2-ns black-70 link dim" title="Call Bogota" href="tel:+18050492">
                            ( +57 ) ( 1 ) 8050492
                        </a>
                        <a className="f6 db fw6 black-70 link dim" title="Call Bogota" href="tel:+573209256153">
                            ( +57 ) 320 925 6153
                        </a>
                    </article>
                </div>

                <div className="mt6">
                    <div className="pa1 w3 bg-light-gray bw1 tc light-gray br-100 bottom-2 right-2">
                        <div className="pa2 bg-moon-gray bw1 br-100 grow-large">
                            <a className="link black" href="http://webmail.hdsas.co/webmail"
                               rel="noopener noreferrer" target="_blank">
                                <Mail/>
                            </a>
                        </div>
                    </div>
                </div>


                <div className="dt dt--fixed mt6 w-100">
                    <div className="dn dtc-ns v-mid">
                        <p className="f7 black-70 dib pr3 mb3">
                            Copyright © HD SAS Company 2020
                        </p>
                    </div>
                    <div className="db dtc-ns black-70 tc tr-ns v-mid">
                        <a href="https://www.facebook.com/" className="link dim dib mr3 black-70"
                           title="Impossible Labs on Facebook">
                            <svg className="db w2 h2" data-icon="facebook" viewBox="0 0 32 32" fill="currentColor">
                                <title>facebook icon</title>
                                <path
                                    d="M8 12 L13 12 L13 8 C13 2 17 1 24 2 L24 7 C20 7 19 7 19 10 L19 12 L24 12 L23 18 L19 18 L19 30 L13 30 L13 18 L8 18 z"
                                ></path>
                            </svg>
                        </a>
                        <a href="https://twitter.com/" className="link dim dib mr3 black-70">
                            <svg className="db w2 h2" data-icon="twitter" viewBox="0 0 32 32"
                                 fill="currentColor">
                                <title>twitter icon</title>
                                <path
                                    d="M2 4 C6 8 10 12 15 11 A6 6 0 0 1 22 4 A6 6 0 0 1 26 6 A8 8 0 0 0 31 4 A8 8 0 0 1 28 8 A8 8 0 0 0 32 7 A8 8 0 0 1 28 11 A18 18 0 0 1 10 30 A18 18 0 0 1 0 27 A12 12 0 0 0 8 24 A8 8 0 0 1 3 20 A8 8 0 0 0 6 19.5 A8 8 0 0 1 0 12 A8 8 0 0 0 3 13 A8 8 0 0 1 2 4"
                                ></path>
                            </svg>
                        </a>
                        <a href="https://medium.com/" className="link dim dib mr3 black-70" title="Impossible Labs on Medium">
                            <svg className="db w2 h2" x="0px" y="0px" viewBox="0 0 290 248.6"
                                 fill="currentColor">
                                <g>
                                    <path fill="currentColor" className="st0"
                                          d="M287.8,46.3L196,0.3c-0.4-0.2-0.9-0.3-1.3-0.3c0,0-0.1,0-0.1,0c-1.1,0-2.2,0.6-2.8,1.5l-56.6,92l63.2,102.7 l90.4-146.9C289.4,48.3,289,46.8,287.8,46.3z"
                                    ></path>
                                    <polygon fill="currentColor" points="105.2,61.2 105.2,160.3 193.3,204.4 	"
                                    ></polygon>
                                    <path fill="currentColor" d="M201,208.2l80.9,40.5c4.4,2.2,8,0,8-5v-180L201,208.2z"
                                    ></path>
                                    <path fill="currentColor"
                                          d="M95.5,46.7L10.7,4.3L5.4,1.7C4.6,1.3,3.8,1.1,3.2,1.1c-0.9,0-1.7,0.4-2.3,1.1C0.3,2.8,0,3.8,0,5v193.4 c0,3.3,2.4,7.2,5.4,8.7l83.3,41.6c1.2,0.6,2.3,0.9,3.3,0.9c2.8,0,4.8-2.2,4.8-5.8V48.7C96.7,47.8,96.2,47.1,95.5,46.7z"
                                    ></path>
                                </g>
                            </svg>
                        </a>
                        <a href="https://www.linkedin.com/company/" className="link dim dib black-70">
                            <svg className="db w2 h2" x="0px" y="0px" viewBox="0 0 48 48">
                                <linearGradient gradientUnits="userSpaceOnUse" x1="23.9995"
                                                y1="0" x2="23.9995" y2="48.0005">
                                    <stop offset="0"></stop>
                                    <stop offset="1"></stop>
                                </linearGradient>
                                <path fill="currentColor"
                                      d="M48,42c0,3.313-2.687,6-6,6H6c-3.313,0-6-2.687-6-6V6 c0-3.313,2.687-6,6-6h36c3.313,0,6,2.687,6,6V42z"
                                ></path>
                                <g>
                                    <g>
                                        <path fill="#FFFFFF"
                                              d="M15.731,11.633c-1.608,0-2.658,1.083-2.625,2.527c-0.033,1.378,1.018,2.494,2.593,2.494 c1.641,0,2.691-1.116,2.691-2.494C18.357,12.716,17.339,11.633,15.731,11.633z M13.237,35.557h4.988V18.508h-4.988V35.557z M31.712,18.748c-1.595,0-3.222-0.329-4.956,2.36h-0.099l-0.087-2.599h-4.417c0.065,1.411,0.074,3.518,0.074,5.52v11.529h4.988 v-9.854c0-0.46,0.065-0.919,0.196-1.248c0.328-0.919,1.149-1.871,2.527-1.871c1.805,0,2.527,1.411,2.527,3.479v9.494h4.988V25.439 C37.455,20.713,34.993,18.748,31.712,18.748z"
                                        ></path>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </footer>
        );
    }
}


