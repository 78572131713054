import React from "react";

function ImaHd(props) {
    return(
    <div className="cover pr1 w-100 overflow-hidden relative ">
        { props.title?
            <span className="db f6 pv2 bg-black-60 pv4 ph4 mr2 mb1 white absolute bottom-0 z-1 w-100 ">{props.title}</span>
          : null
        }
        <img src={process.env.PUBLIC_URL + '/' +  props.url} className="w-100 br1 grow" alt={props.title}/>
    </div>
    );
}

export default ImaHd;
