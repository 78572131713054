import React from 'react';
import ImaHd from "./Imagen";

export default class Footer extends React.Component {
    render() {
        return (
            <div id="tag-servicios" className="fl w-100">
                <div className="fl w-100">
                    <article className="fl w-100 w-50-ns pa3 pa5-ns">
                        <div className="ba bl-0 bt-0 br-0 b--solid b--black-30 w-20 mt5"></div>
                        <h1 className="f4 f3-m f2-l">Obras Ambientales</h1>
                        <ul className="list pl0 measure center">
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Monitoreo en cuerpos de agua.
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Revegetalizacion y reforestación.
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Inventarios forestales.
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                EIA estudio de impacto ambiental.
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Manejo y traslado de epifitas
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Control de contaminación ambiental.
                            </li>
                        </ul>
                    </article>

                    <div className="fl w-100 w-50-m w-50-l v-mid ">
                        <div className="mt2 mt6-ns">
                            <ImaHd url="linea4_compactador_basura_hd.jpg"/>
                        </div>
                    </div>

                </div>
                <div className="fl w-100">

                    <div className="fl w-100 w-50-m w-50-l v-mid ">
                        <div className="mt2 mt5-ns">
                            <ImaHd url="linea4_bomberos_hd.jpg"/>
                        </div>
                    </div>

                    <article className="fl w-100 w-50-ns pa3 pa5-ns">
                        <div className="ba bl-0 bt-0 br-0 b--solid b--black-30 w-20 mt5"></div>
                        <h1 className="f4 f3-m f2-l">Obras Civiles</h1>
                        <ul className="list pl0 measure center">
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Construcción de obras de urbanismo
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Construcción  y remodelación de edificaciones.
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Construcción de obras viales y de infraestructura en general.
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Redes Hidráulicas y sanitarias.
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Plantas de tratamiento de  agua Potable y Residual.
                            </li>
                        </ul>
                    </article>
                </div>
                <div className="fl w-100">
                    <div className="fl w-100 w-50-m w-50-l">
                        <div className="mt2 mt6-ns">
                            <ImaHd url="linea4_Succion_Presion_hd.jpg"/>
                        </div>
                    </div>

                    <article className="fl w-100 w-50-ns pa3 pa5-ns">
                        <div className="ba bl-0 bt-0 br-0 b--solid b--black-30 w-20 mt5"></div>
                        <h1 className="f4 f3-m f2-l">Montajes</h1>
                        <ul className="list pl0 measure center">
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Soldadura Tipo Smaw ( Soldadura por arco manual con electrodos revestidos.
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Montajes mecánicos.
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Mantenimiento de ductos.
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Soldadura semiautomática API tipo inner – shield.
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Soldadura manual bajo norma API y código ASME.
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Construcción de trampas de recibo – sector de hidrocarburos.
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Equipos de Saneamiento Básico Ambiental.
                            </li>
                        </ul>
                    </article>
                </div>
                <div className="fl w-100">
                    <article className="fl w-100 w-50-ns pa3 pa5-ns">
                        <div className="ba bl-0 bt-0 br-0 b--solid b--black-30 w-20 mt5"></div>
                        <h1 className="f4 f3-m f2-l">Mantenimiento, Adecuación y Repuestos</h1>
                        <ul className="list pl0 measure center">
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Servicio de mantenimiento y reparación, con suministro de repuestos y
                                restauración de pintura, de vehículos multimarca.
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Adecuación de  suelos de cultivos de palma
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Adecuación de  suelos de cultivos de Arroz
                            </li>
                            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
                                Adecuación y  mantenimiento de zonas verdes
                            </li>
                        </ul>
                    </article>
                    <div className="fl w-100 w-50-m w-50-l">
                        <div className="mt2 mt5-ns">
                            <div className="mt2 mt2-ns">
                                <ImaHd url="linea4_Motos_hd.jpg"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
