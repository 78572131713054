import React from 'react';
import { ArrowUp } from 'react-feather';
import Footer from './Footer';
import NavBar from './NavBar';
import Company from './Company';
import Services from './Services';
import ImaHd  from './Imagen'

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      scroll: false
    };
  }

  isInTop (){
    let doc = document.documentElement;
    let tmpScroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    return tmpScroll;
  }

  componentDidMount() {
    this.timerID = setInterval(
        () => {
          this.setState({scroll: this.isInTop() > 0 ? true : false})
        },
        2000
    );
  }

  scrollUp () {
    let top =this.isInTop();
    if (top > 0) {
      window.scrollTo(0, top - 10);
      setTimeout(()=>this.scrollUp(), 10);
    }
  }

  render() {

    const { scroll } = this.state;

    return (
        <main id="hd" className="w-100 system-sans-serif bg-white" data-gr-c-s-loaded="true">
          <div data-name="component">

            <main className="w-100 cf helvetica dark-gray bg-white  pt0 ph3 mw9 center">

              <NavBar/>

              <div className="fl">
                <div className="cover pr1 pb0">
                  <img  src={process.env.PUBLIC_URL +  '/vacuum_cleaners_hd2.jpg'}
                        className="mw-100" alt="night sky over water"/>
                </div>
              </div>

              <Company/>

              <div className="fl w-100 ">
                <div className="fl w-100 w-33-m w-33-l">
                  <ImaHd url="succionPresion1_hd.jpg" title="Succión Presión 12y"/>
                </div>
                <div className="fl w-100 w-33-m w-33-l">
                  <ImaHd url="succionPresion2_hd.jpg" title="Succión Presión 7y"/>
                </div>
                <div className="fl w-100 w-33-m w-33-l">
                  <ImaHd url="succionPresion3_hd.jpg" title="Succión Presión 8y"/>
                </div>
                <div className="fl w-100">
                  <ImaHd url="succionPresion4_hd.jpg" title="Succión Presión 16y"/>
                </div>
              </div>

              <div className="fl w-100 ">
                <div className="fl w-100 w-50-m w-25-l">
                  <ImaHd url="linea3_barredora_hd.jpg" title="Barredoras"/>
                </div>
                <div className="fl w-100 w-50-m w-25-l">
                  <ImaHd url="linea3_cabrestante_hd.jpg" title="Cabrestantes"/>
                </div>
                <div className="fl w-100 w-50-m w-25-l">
                  <ImaHd url="linea3_caja_Estacionaria_hd.jpg" title="Cajas Estacionarias"/>
                </div>
                <div className="fl w-100 w-50-m w-25-l">
                  <ImaHd url="linea3_rotasonda_hd.jpg" title="Rotasondas"/>
                </div>
              </div>

              <Services/>


              <div className="fl w-100 pv3">
                <div className="fl w-100 w-50-m w-50-l">
                    <ImaHd url="linea5_tractor_hd.jpg" title="Maquinaria Agricola"/>
                </div>
                <div className="fl w-100 w-50-m w-25-l">
                    <ImaHd url="linea5_CarroPala_hd.jpg" title="Maquinaria Amarilla"/>
                </div>
                <div className="fl w-100 w-50-m w-25-l">
                    <ImaHd url="linea5_plancha_hd.jpg" title="Gruas y Cama Bajas"/>
                </div>
                <div className="fl w-100 w-50-m w-25-l">
                    <ImaHd url="linea5_bomberos_hd.jpg" title="Vehiculos para Emergencias"/>
                </div>
                <div className="fl w-100 w-50-m w-25-l">
                  <ImaHd url="linea5_bus_pasajeros_hd.jpg" title="Vehiculos de Pasajeros"/>
                </div>
              </div>

              <div className="fl w-100 pv3">
                <div className="fl w-100 w-50-m w-33-l">
                  <ImaHd url="linea6_policia_palm_hd.jpg" title="Vehiculos de Seguridad"/>
                </div>
                <div className="fl w-100 w-50-m w-33-l">
                  <ImaHd url="linea6_inpec_palm_hd.jpg" title="Vehiculos Panel"/>
                </div>
                <div className="fl w-100 w-50-m w-33-l">
                  <ImaHd url="linea6_ambulancia_hd.jpg" title="Vehiculos de Emergencias"/>
                </div>
                <div className="fl w-100 w-50-m w-50-l">
                  <ImaHd url="linea6_cai_movil_hd.jpg" title="Seguridad Urbana"/>
                </div>
                <div className="fl w-100 w-50-m w-50-l">
                  <ImaHd url="linea6_migracion_palm_hd.jpg" title="Equipos Telematicos"/>
                </div>
              </div>

              <Footer/>

            </main>
          </div>


          { scroll ?
              <div className="pa1 z-3 bg-light-gray fixed  bw1 light-gray br-100 bottom-2  right-2">
                <div className="pa2 bg-moon-gray bw1 br-100 grow-large  ">
                  <a onClick={() =>this.scrollUp()} href="#tag-hd">
                    <ArrowUp/>
                  </a>
                </div>
              </div>
              : null
          }

        </main>
    );
  }
}



