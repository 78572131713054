import React from 'react';

export default class NavBar extends React.Component {
    render() {
        return (
        <article id="tag-hd"  className="cf fl w-100 ph3 ph5-ns pv5">
            <div className="ba bl-0 bt-0 br-0 b--solid b--black-30 w-20 mt5"></div>
            <header className="fn fl-ns w-50-ns pr4-ns">
                <h1 className="f2-ns f4 lh-title fw9 mb3 mt0 pt3 bw2">
                    HD SAS
                </h1>
                <h2 className="f5-ns f6 mid-gray lh-title">
                    28 años contratando con el estado Colombiano
                </h2>
                <time className="f6-ns f7 ttu tracked gray">  </time>
            </header>
            <div className="fn fl-ns w-50-ns">
                <p className="f5-ns f6 lh-copy measure tj">
                    HD SAS esta encaminada a proporcionar soluciones a entidades públicas y privadas, desde el desempeño
                    industrial y comercial, aportando al avance tecnológico y desarrollo social, gracias a la
                    experiencia y gestión de nuestro equipo de profesionales.
                </p>
                <p className="f5 lh-copy measure tj">
                    HD SAS es una organizacion Colombiana con proyección a nivel andino, ofreciendo servicio,
                    saneamiento básico ambiental, y suministro, con efectividad administrativa, técnica, y operativa,
                    generando beneficios a las partes interesadas de la organización. Mantiene el compromiso constante y
                    renovado frente a la  seguridad de sus colaboradores, la protección del medio ambiente y la
                    mitigación de los riesgos e impactos asociados a la ejecución de suministro y saneamiento
                    básico ambiental. Busca el aumento de la satisfacción del cliente y partes interesadas, mediante el
                    cuidado de su propiedad y el cumplimiento adquirido de los requisitos legales, técnicos,
                    organizacionales.
                </p>
            </div>
        </article>
        )
    }
}


